import React, { useContext, useRef } from 'react';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Carousel } from 'antd';
import { graphql, useStaticQuery } from 'gatsby';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import { StrapiCourseType, StrapiPurchasedContentType } from '../../types';
import useMediaQuery from '../../utils/hooks/useMediaQuery';
import CourseCard from '../CourseCard/CourseCard';

const CourseCarousel = () => {
  const data = useStaticQuery(graphql`
    query carouselData {
      allStrapiCourse {
        nodes {
          isActive
          slug
          price
          strapi_id
          altText
          description
          name
          id
          lessons {
            strapi_id
          }
          progress
          themeColor
          picture {
            localFile {
              url
            }
          }
        }
      }
      allStrapiPurchasedContent {
        nodes {
          courses {
            id
          }
          owner {
            strapi_id
          }
        }
      }
    }
  `);
  const { isTablet, isDesktop } = useMediaQuery();
  const carouselRef = useRef<any>(null);

  const carouselProps = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: isDesktop ? 3 : isTablet ? 2 : 1,
    slidesToScroll: 1,
  };

  const {
    state: { authenticated },
  } = useContext(GlobalStateContext);

  const courses = data?.allStrapiCourse?.nodes;
  const userId =
    typeof window !== 'undefined' && localStorage?.getItem('userid');
  const purchasedCourses: StrapiPurchasedContentType[] = userId
    ? data?.allStrapiPurchasedContent.nodes.filter(
        (course: StrapiPurchasedContentType) =>
          course.owner.strapi_id === parseInt(userId)
      )
    : [];

  const purchasedCoursesIds = purchasedCourses
    .map(data => data.courses.map(course => course.id))
    .reduce((accumulator, value) => accumulator.concat(value), []);

  const Cards = courses.map((card: StrapiCourseType) => (
    <CourseCard
      purchased={purchasedCoursesIds.includes(card.id)}
      key={card.id}
      strapi_id={card.strapi_id}
      id={card.id}
      name={card.name}
      image={card.picture?.localFile?.url}
      description={card.description}
      altText={card.altText}
      progress={authenticated ? card.progress : null}
      slug={card.slug}
      lessons={card.lessons}
      themeColor={card.themeColor}
      isActive={card.isActive}
      price={card.price}
      stripeId={card.stripeId}
    />
  ));

  return (
    <div className="course-carousel-container">
      {(isDesktop || isTablet) && (
        <Button
          icon={<LeftOutlined className="arrow-icon" />}
          onClick={() => carouselRef.current?.prev()}
        />
      )}
      <div className="carousel-main">
        <Carousel
          className="testimonial-section-carousel carousel-dots"
          ref={carouselRef}
          {...carouselProps}
        >
          {Cards}
        </Carousel>
      </div>
      {(isDesktop || isTablet) && (
        <Button
          icon={<RightOutlined className="arrow-icon" />}
          onClick={() => carouselRef.current?.next()}
        />
      )}
    </div>
  );
};

export default CourseCarousel;
