import React, { useEffect, useState } from 'react';
import CourseCarousel from '../components/CourseCarousel/CourseCarousel';
import AddToCartButton from '../components/Cart/AddToCartButton';
import { ROUTES } from '../constants/routes';
import { Layout } from '../components/Layout/Layout';
import useMediaQuery from '../utils/hooks/useMediaQuery';
import Markdown from 'markdown-to-jsx';
import { graphql, Link } from 'gatsby';
import { Typography, Divider, Row, Col, Image, Button, Breadcrumb } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import './products.less';

type ProductsProps = {
  data: {
    allStrapiProduct: {
      nodes: [
        {
          id: number;
          productId: number;
          stripeId: string;
          price: number;
          name: string;
          description: {
            data: {
              description: string;
            };
          };
          offerIncluded: {
            data: {
              offerIncluded: string;
            };
          };
          offerExcluded: {
            data: {
              offerExcluded: string;
            };
          };
          picture: {
            localFile: {
              url: string;
            };
          };
        }
      ];
    };
  };
};

const Products = ({ data }: ProductsProps) => {
  const { isTablet } = useMediaQuery();
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  const { Title, Text } = Typography;

  const basicPlanId = 1;
  const plusPlanId = 2;
  const premiumPlanId = 3;
  const singlePlanId = 4;
  const basicPlanIndex = basicPlanId - 1;
  const singlePlanIndex = singlePlanId - 1;

  const products = data.allStrapiProduct.nodes.sort(
    (a, b) => a.productId - b.productId
  );
  const currentProduct = products[activeProductIndex];

  const setURL = (id: number) => {
    window.history.pushState(
      {},
      '',
      `${window.location.href.split('?')[0]}?${id}`
    );
  };

  useEffect(() => {
    const key = window.location.href.split('?')[1];
    const productindex = products.findIndex(item => item.productId == key);
    const currentItemIndex = productindex === -1 ? 0 : productindex;
    setActiveProductIndex(currentItemIndex);
    setURL(products[currentItemIndex].productId);
  }, [setURL]);

  return (
    <Layout>
      <section className="products-page">
        <Row className="section-content-container">
          <Col span={24}>
            <Breadcrumb separator={<ArrowRightOutlined />}>
              <Breadcrumb.Item href={ROUTES.HOME}>
                Strona główna
              </Breadcrumb.Item>
              <Breadcrumb.Item href={ROUTES.PRODUCTS}>Produkty</Breadcrumb.Item>
            </Breadcrumb>
            <Divider></Divider>
            <Title className="title-product-page" level={3}>
              Nasze produkty
            </Title>
          </Col>
          <div className="product-buttons-container-wrap">
            <div className="product-buttons-container">
              {products.map((product, index) => (
                <Button
                  key={index}
                  className={
                    index === activeProductIndex
                      ? 'product-button active'
                      : 'product-button'
                  }
                  onClick={() => {
                    setActiveProductIndex(index);
                    setURL(product.productId);
                  }}
                >
                  {product.name}
                </Button>
              ))}
            </div>
          </div>
          <Col className="products-col">
            <Row className="products-title-image-row">
              <Col span={isTablet ? 12 : 24}>
                <Title className="products-title" level={3}>
                  {currentProduct?.name}
                </Title>
                <div className="products-description">
                  {currentProduct?.description?.data?.description}
                </div>
                <div className="products-description products-price">
                  {currentProduct?.price} zł
                </div>
                <div className="products-button-cart-container">
                  <AddToCartButton
                    id={currentProduct.id}
                    stripeId={currentProduct.stripeId}
                    name={currentProduct?.name}
                    isActive={true}
                  />
                </div>
              </Col>
              <Col span={isTablet ? 12 : 24}>
                <div className="products-image-container">
                  <Image
                    preview={false}
                    alt="vector graphics - several books, apple, office supplies, coffee, globe"
                    src={currentProduct?.picture.localFile.url}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <Markdown>
                  {currentProduct.offerIncluded?.data?.offerIncluded || ''}
                </Markdown>
                {activeProductIndex === basicPlanIndex && (
                  <Divider className="offer-divider" />
                )}
              </Col>
              <Markdown>
                {currentProduct?.offerExcluded?.data?.offerExcluded || ''}
              </Markdown>
              {activeProductIndex === basicPlanIndex && (
                <Text>
                  Sprawdź{' '}
                  <Link
                    to={`${ROUTES.PRODUCTS}?${plusPlanId}`}
                    className="link-underline"
                  >
                    Pakiet Plus
                  </Link>{' '}
                  i{' '}
                  <Link
                    to={`${ROUTES.PRODUCTS}?${premiumPlanId}`}
                    className="link-underline"
                  >
                    Pakiet Premium
                  </Link>
                  !
                </Text>
              )}
            </Row>
          </Col>
        </Row>
        {activeProductIndex === singlePlanIndex && <CourseCarousel />}
      </section>
    </Layout>
  );
};

export const query = graphql`
  query {
    allStrapiProduct {
      nodes {
        id
        productId
        stripeId
        price
        name
        description {
          data {
            description
          }
        }
        picture {
          localFile {
            url
          }
        }
        offerIncluded {
          data {
            offerIncluded
          }
        }
        offerExcluded {
          data {
            offerExcluded
          }
        }
      }
    }
  }
`;

export default Products;
